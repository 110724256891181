import React from "react";
import SEO from "../components/SEO";

import withLayout from "../components/hoc/withLayout";

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist.</p>
  </div>
);

export default withLayout(NotFoundPage);
